import {Observable} from 'rxjs';
import {useState, useEffect} from 'react';

export function useObs<T>(obs?: Observable<T>): T | undefined {
  const [state, setState] = useState<T>();
  useEffect(() => {
    const sub = obs && obs.subscribe(setState);
    return () => sub && sub.unsubscribe();
  }, [obs]);
  return state;
}