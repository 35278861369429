import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import { Header } from './components/header/header';
import { Row, IRowData, RowData, RowHeader, RowDataHeader } from './components/row/row';
import { dollar } from './utils/number.util';
import { useStorage } from './utils/save.util';
import { useObs } from './utils/rxjs.util';
import { requestError$ } from './services/sendRequest.service';
import { Error } from './error/error';
import { exportRows, importRows } from './services/importExport.service';
import { FileInput } from './components/fileInput/fileInput';

function App() {
  const [total, setTotal] = useStorage<number>('total', 0);
  const [rows, setRows] = useStorage<IRowData[]>('rows',[{ shares: 1, symbol: ''}]);
  const [error, setError] = useState();
  const addRow = () => setRows((oldRows) => [...oldRows, { shares: 1, symbol: ''}]);
  const errorObs = useObs(requestError$);
  const updateRow = useCallback((index: number, data: Partial<IRowData>) => {
    setRows((oldRows) => {
      const newRows = [...oldRows];
      newRows.splice(index, 1, {...newRows[index], ...data});
      return newRows
    });
  }, [setRows]);

  const removeRow = (index: number) => {
    setRows((oldRows) => {
      const newRows = [...oldRows];
      newRows.splice(index, 1);
      return newRows;
    });
  }

  useEffect(() => { 
    setTotal(rows.reduce((acc, {data, shares}) => data ? acc + (data.total * shares) : acc, 0));
  }, [rows, setTotal]);
  useEffect(() => setError(errorObs), [errorObs]);
  const clear = () => setRows([{ shares: 1, symbol: ''}]);
  const refreshAll = () => setRows((oldRows) => oldRows.map((r) => ({...r, refresh: !r.refresh})));
  const exportAll = () => exportRows(rows);
  const importAll = (file?: File) => file && importRows(file).then(setRows);
  return (
    <div className="App-Wrapper">
      <div className="App">
        <Header total={total}/>
        <div className="content">
          <div className="row">
            <RowHeader></RowHeader>
            <RowDataHeader></RowDataHeader>
          </div>
          {
            rows.map((v: IRowData, index: number) => (
              <div className="row" key={v.data ? v.data.symbol: index}>
                <Row defaultValue={v} index={index} updateRow={updateRow} remove={() => removeRow(index)} addRow={addRow}/>
                <RowData data={v.data} shares={v.shares} symbol={v.data && v.data.symbol} refresh={v.refresh}/>
              </div>
            ))
          }
          <button onClick={addRow}>Add Row</button>
          <button onClick={refreshAll}>Refresh</button>
          <button onClick={clear}>Clear</button>
          <FileInput onUpload={importAll}>Import</FileInput>
          <button onClick={exportAll}>Export</button>
          <div>{dollar(total)}</div>
        </div>
        <div className="footer"><a href="https://iexcloud.io">Data provided by IEX Cloud</a></div>
      </div>
      <Error error={error && error.message} onClose={() => setError(undefined)}></Error>
    </div>
  ); // TODO_EB format the data provided by message
}

export default App;
