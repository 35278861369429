import { useState, useEffect, SetStateAction, Dispatch } from "react";

const storage = window.localStorage;

const set = <T>(k: string, v: T) => storage.setItem(k, JSON.stringify(v));
const get = <T>(k: string): T => JSON.parse(storage.getItem(k) || '""');

export function useStorage<T>(key: string, data: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(get(key) || data);
  useEffect(() => set(key, state), [key, state]);
  return [state, setState];
}