import { Observable, of, Subject } from "rxjs";
import { fromFetch } from 'rxjs/fetch';
import { filter, switchMap, map, tap, catchError, pluck, delay, concatMap, share } from 'rxjs/operators'
import { KEY } from "./iexcloud.constant";
const apiEndpoint = 'https://cloud.iexapis.com/stable';
const TIMEOUT = 50;
interface IRequestError {
  error: boolean;
  message: string;
}
const q = new Subject<[string, Subject<Response>]>();
q.pipe(concatMap(([path, sub]) => {
  const obs = fromFetch(path).pipe(share());
  obs.subscribe(sub);
  return obs.pipe(delay(TIMEOUT));
})).subscribe();

function queue(path: string): Observable<Response> {
  const mapper = new Subject<Response>();
  q.next([path, mapper]);
  return mapper;
}

const requestErrorSubject: Subject<IRequestError> = new Subject();
export const requestError$ = requestErrorSubject.asObservable();

const isRequestError = (maybeError: unknown): maybeError is IRequestError => typeof maybeError === 'object' && !!maybeError && 'error' in maybeError;

function sendRequest<T>(path: string, params: Record<string, string>): Observable<T> {
  const paramString = [['token', KEY], ...Object.entries(params)].map(([key, value]) => `${key}=${value}`).join('&');
  return queue(`${apiEndpoint}/${path}?${paramString}`)
    .pipe(
      switchMap((r: Response): Promise<T> | Observable<IRequestError> => r.ok ? r.json() : of({error: true, message: `Error (${r.status}): "${r.statusText}"`})),
      catchError((e: Error) => of({error: true, message: `Error: ${e.message}`})),
      tap((response: T | IRequestError) => isRequestError(response) && requestErrorSubject.next(response)),
      filter((response: T | IRequestError): response is T => !isRequestError(response)),
    );
}

interface IDividendResponse {
  symbol: string;
  date: string;
  amount: number;
  frequency: string;
}

function getDividends(symbol: string): Observable<[number, string]> {
  return sendRequest<IDividendResponse[]>(`stock/${symbol}/dividends/ytd`, {}).pipe(
    map(([response]) => response ? [response.amount, response.frequency] : [0, '']),
  );
}
const freqWarn = (freq: string) => {
  console.warn(`Could not identify frequency ${freq}`);
  return 0;
}
const divPerYear = (freq: string) => (
  freq === 'quarterly' ? 4 :
  freq === 'monthly' ? 12 :
  freqWarn(freq)
);
export function getCurrentPrice(symbol: string): Observable<number> {
  return sendRequest<{latestPrice: number}>(`stock/${symbol}/quote`, {}).pipe(pluck('latestPrice'));
}

export interface IDividendData {
  symbol: string;
  dividend: number;
  total: number;
}

export function getDividendData(symbol: string): Observable<IDividendData | undefined> {
  return symbol ? getDividends(symbol.toLocaleUpperCase()).pipe(
    map(([dividend, frequency]) => ({
      symbol,
      dividend,
      total: dividend * divPerYear(frequency),
    }))
  ) : of(undefined);
}

export function supportedTickers(): void {
  sendRequest('stock/symbol', {exchange: 'US'}).subscribe((v) => console.log(JSON.stringify(v)));
}

export function getStockName(symbol: string): Observable<string> {
  return sendRequest<{companyName: string}>(`stock/${symbol}/company`, {}).pipe(pluck('companyName'));
}