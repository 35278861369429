import React, { useEffect, useState } from 'react';
import './error.css';

const WRAPPER = 'error-wrapper';
const VISIBLE = 'visible';
const ERROR_TIMEOUT = 5000;

export function Error({error, onClose}: {error?: string, onClose: () => void}) {
  const [classes, setClasses] = useState(WRAPPER);
  const [visible, setVisible] = useState(false);
  useEffect(() => setVisible(!!error), [error]);
  useEffect(() => { visible && setTimeout(() => setVisible(false), ERROR_TIMEOUT) }, [visible]);
  useEffect(() => setClasses(`${WRAPPER} ${visible ? VISIBLE: ''}`.trim()), [visible]);
  const onClick = () => {
    setVisible(false);
    onClose();
  };
  return (
    <div className={classes}>
      <div className="error-bubble">
        <span className="text">{error}</span>
        <div title="Close" className="x" onClick={onClick}>✖</div>
      </div>
    </div>
  );
}