import { IRowData } from "../components/row/row";
import { IDividendData } from "./sendRequest.service";

function download(filename: string, data: string, type?: string): void {
  const anchor = document.createElement('a');
  const blob = new Blob([data], {type});
  const url = URL.createObjectURL(blob);
  anchor.download = filename;
  anchor.href = url
  anchor.style.visibility = 'hidden';
  document.body.append(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(url);
}

const getSymbol = (symbol?: string, data?: IDividendData): string => (
  data ? data.symbol :
  symbol ? symbol :
  ''
);
const getDividendStr = (data?: IDividendData): string[] => data ? [ data.dividend.toString(), data.total.toString() ] : ['', ''];
const mkRow = ({symbol, shares, data}: IRowData): string =>
  [getSymbol(symbol, data), shares, ...getDividendStr(data)].join(',') + '\n';

export function exportRows(rows: IRowData[]): void {
  const d = rows.reduce((acc: string, row: IRowData) => acc.concat(mkRow(row)), '');
  download('dividend-demon-export.csv', d, 'text/csv');
}

function uploadFile(file: File, type?: string): Promise<string> {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => e.target && e.target.result !== null ? res(e.target.result.toString()) : rej('No target');
    reader.readAsText(file, type); 
  })
} 

const lineToRow = (line: string): IRowData => {
  const [symbol, shares, dividend, total] = line.split(',') as Array<string | undefined>;
  return {
    symbol,
    shares: shares ? +shares : 1,
    data: symbol ? {
      dividend: dividend ? +dividend : 0,
      total: total ? +total : 0,
      symbol,
    } : undefined
  }
};

export function importRows(file: File): Promise<IRowData[]> {
  return uploadFile(file).then((fileData: string) => fileData.split('\n').map(lineToRow));
}