import React, {useRef, FunctionComponent} from 'react';
import './fileInput.css';

interface IFileInputProps {
  onUpload: (file?: File) => void;
}

export const FileInput: FunctionComponent<IFileInputProps> = ({onUpload, children}) => {
  let inputRef = useRef<HTMLInputElement>(null);
  const upload = () => {
    const input = inputRef.current;
    input && input.files ? onUpload(input.files[0]) : onUpload();
  };
  const clickHandler = () => {
    const input = inputRef.current;
    input && input.click();
  };
  return (
    <button onClick={clickHandler}>
      {children}
      <input className="file-input" type="file" onChange={upload} ref={inputRef}></input>
    </button>
  );
}