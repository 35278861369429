import React, { ChangeEvent, useState, useEffect, KeyboardEvent } from 'react';
import { Subject, Observable } from 'rxjs';
import { getDividendData, IDividendData, getCurrentPrice, getStockName } from '../../services/sendRequest.service';
import { useObs } from '../../utils/rxjs.util';
import { dollar } from '../../utils/number.util';
import { switchMap, tap, debounceTime } from 'rxjs/operators';
import './row.css';

const debounce = 500;

const DEFAULT_SHARES = 1;
export interface IRowData {
  data?: IDividendData;
  shares: number;
  symbol?: string;
  refresh?: boolean;
}

export const RowData = ({data, shares, symbol, refresh}: IRowData) => {
  const [priceObs, setPriceObs] = useState<Observable<number>>();
  const [nameObs, setNameObs] = useState<Observable<string>>();
  useEffect(() => {
    if (symbol) {
      setPriceObs(getCurrentPrice(symbol));
      setNameObs(getStockName(symbol));
    }
  }, [symbol, refresh]);
  const price = useObs(priceObs);
  const name = useObs(nameObs);
  return data ? (
    <div className='grid'>
      <div title={name || data.symbol.toLocaleUpperCase()}>{name || data.symbol.toLocaleUpperCase()}</div>
      <div>{dollar(price || 0)}</div>
      <div>{dollar(data.dividend)}</div>
      <div>{dollar(data.total)}</div>
      <div>{dollar(data.total * shares)}</div>
    </div>
  ) : null}
;

export const RowDataHeader = () => (
  <div className='grid'>
    <div>Name</div>
    <div>Price</div>
    <div>Last Dividend</div>
    <div>Dividend / Year </div>
    <div>Total Dividend</div>
  </div>
);

interface IRowProps {
  defaultValue: IRowData;
  updateRow: (index: number, data: Partial<IRowData>) => void;
  addRow: () => void;
  remove: () => void;
  index: number
}

export const Row = ({defaultValue, updateRow, addRow, remove, index}: IRowProps) => {
  const [inputSubject] = useState(new Subject<string>());
  const [, setShares] = useState(DEFAULT_SHARES);
  const [ticker, setTicker] = useState(defaultValue.data ? defaultValue.data.symbol : '');
  const [refresher, setRefresher] = useState(defaultValue.refresh || false);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTicker(event.target.value);
  };

  useObs(inputSubject.asObservable().pipe(
    debounceTime(debounce),
    switchMap(getDividendData),
    tap((d?: IDividendData) => {
      setRefresher(!refresher);
      updateRow(index, {data: d, refresh: !refresher})
    })
  ));

  useEffect(() => {
    inputSubject.next(ticker)
  },
    [ticker, inputSubject]
  );

  useEffect(() => {
    defaultValue.data && setTicker(defaultValue.data.symbol);
  }, [defaultValue]);


  const setSharesData = (event: ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;
    setShares(value);
    updateRow(index, {shares: value});
  };
  const maybeEnter = (event: KeyboardEvent<HTMLInputElement>) => { event.key === 'Enter' && setTimeout(addRow, debounce + 25); };
  const refresh = () => {
    inputSubject.next(ticker);
  };
  useEffect(() => updateRow(index, {refresh: refresher}), [refresher, updateRow, index]);
  return (
    <div className='row'>
      <input autoFocus onChange={handleInputChange} value={ticker} onKeyPress={maybeEnter}/>
      <input type="number" onChange={setSharesData} min={0} defaultValue={defaultValue.shares}/>
      <button onClick={refresh}>Refresh</button>
      <button onClick={remove}>Remove</button>
    </div>
  )
};

export const RowHeader = () => (
  <div className='row header'>
    <input/>
    <input type="number"/>
    <button>Refresh</button>
    <button>Remove</button>
  </div>
)
